import Styles from './Source15.scss';

import Template from './Source15.hbs';
import Hedy from 'views/components/taskGroups/tasks/template38/hedy/Hedy';
import Util from 'util/util';

export default BaseView.extend({

    initialize() {

        this.title = Util.renderContentSafely(this.model.get('title'))
        this.source = this.model.get('source')

        this.setElement(Template({
            Styles,
            title: this.title
        }))

        this.hedyView = this.addChildView(new Hedy({
            level: this.source.level,
            program: this.source.program,
            showRun: false,
            readOnly: true
        }), '.js-hedy-source')
    }
});
