import Styles from './SSOBar.scss';

import Template from './SSOBar.hbs';
export default BaseView.extend({

    /**
     *
     * @param {Object} options Options
     *
     * @param {boolean} options.fromLoginScreen SSO bar is displayed at login view
     */
    initialize({
        fromLoginScreen = false,
    }) {

        const consumers = Backbone.Model.app_version.get('consumers')
        this.setElement(Template({
            Styles,
            hasSOMLogin: consumers.some(consumer => consumer.consumer_id === 2),
            hasEntreeLogin: consumers.some(consumer => consumer.consumer_id === 4),
            hasGoogleLogin: consumers.some(consumer => consumer.consumer_id === 5),
            hasOffice365Login: consumers.some(consumer => consumer.consumer_id === 6),
            hasFourConsumers: (consumers.length === 4),
            /**
             * after successfully being authenticated, redirect to
             * last known page
             */
            lastLocation: !fromLoginScreen ?
                encodeURIComponent(`/${Backbone.history.getFragment()}`) :
                null,
        }))
    }
})
