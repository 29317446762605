import Styles from './Hedy.scss';

import Template from './Hedy.hbs';
import Util from 'util/util'

export default class Hedy extends BaseView {

    get hedyFrameSource() {
        const url = 'https://hedycode.com';
        return url + `/embedded/${this.level}/?lang=nl&run=${this.showRun}&readOnly=${this.readOnly}&fullWidth=${this.fullWidth}${this.program.length ? `&program=${encodeURIComponent(Util.base64Encode(this.program))}` : ''}`
    }

    get iframe() {
        return this.el.contentWindow
    }

    initialize({
        level = 1,
        program = '',
        fullWidth = false,
        showRun = false,
        readOnly = false
    }) {

        _.bindAll(
            this,
            'editorInputListener',
        )

        this.level = level
        this.program = program
        this.fullWidth = fullWidth;
        this.showRun = showRun
        this.readOnly = readOnly

        this.render()

    }

    render() {
        // Set the height dynamically because Hedy renders always to the full height of the iframe
        // Therefore we have to determine the height before rendering the template

        let height = this.fullWidth ? 700 : 400;
        if (!this.showRun && this.readOnly) {
            height = (this.program.split(/\r\n|\r|\n/).length + 1) * 20
            Math.min(height, 400)
        }

        this.replaceTemplate(Template({
            Styles,
            cid: this.cid,
            src: this.hedyFrameSource,
            height: height + 'px'
        }))

        this.el.addEventListener('load', () => {
            window.addEventListener('message', this.editorInputListener)
        }, {once: true})
    }

    editorInputListener(message) {
        if (message.source === this.iframe && message.data.change) {
            this.program = message.data.program
            this.trigger('codeEditorChange')
        } else if (message.source === this.iframe && message.data.run) {
            this.program = message.data.program
            this.trigger('programExecuted')
        }
    }

    onDestroy() {
        window.removeEventListener('message', this.editorInputListener)
    }

}
