import Styles from './PageSpinner.scss';

import Template from './PageSpinner.hbs';
import Spinner from 'views/components/spinner/Spinner';
import Button from 'views/components/button/Button'

export default BaseView.extend({

    blockHiding: false,

    /**
     * initialize
     *
     * Initializing function, which will be called on creation. It
     * will create a DOM element based on the given template.
     */
    initialize() {

        // Create the modal element, passing the styling with it
        this.setElement(Template({
            Styles
        }));

        this.addChildView(new Spinner(), '.js-spinner');
    },

    setRetryCallback(callback) {
        if (callback) {
            this.retryButton = this.addChildView(new Button({
                label: window.i18n.gettext('Try again'),
                inline: true,
                icon: 'refresh',
                callback
            }), '.js-message');

            this.$('.js-spinner').hide();
            this.$('.js-message').css({display: 'grid'})

        } else {

            this.$('.js-spinner').show();
            this.$('.js-message').hide();

            if (this.retryButton) {
                this.retryButton.destroy();
            }

        }
    },

    show(retryCallback) {
        this.setRetryCallback(retryCallback);
        this.$el.css({
            display: 'flex'
        });
    },

    hide() {
        if (!this.blockHiding) {
            this.$el.hide();
            this.setRetryCallback();
        }
    }

});
