import Styles from './ChapterView.scss';

import Template from './ChapterView.hbs';
import ContentLabel from 'views/components/contentLabel/ContentLabel'
import ProgressBar from 'views/components/progressBar/ProgressBar.svelte'
import SectionItemTemplate from './SectionItemTemplate.hbs'
import ContentLabelModel from 'models/ContentLabelModel'

export default class ChapterView extends BaseView {

    initialize() {

        let courseBrandingLogo
        if (this.model.get('course_branding_id')) {
            courseBrandingLogo = Backbone.Collection.course_branding.get(this.model.get('course_branding_id'))?.get('logo_white')
        }

        const contentLabelModel = new ContentLabelModel(null, {layerModel: this.model})

        this.setElement(Template({
            Styles,
            id: this.model.id,
            index: contentLabelModel.get('index'),
            title: contentLabelModel.get('title'),
            name: contentLabelModel.get('name'),
            isNumberless: contentLabelModel.get('isNumberless'),
            background: this.model.get('image'),
            backgroundPos: this.model.get('background_image_position'),
            courseBrandingLogo,
        }))

        this.model.sections.forEach((section) => {
            const sectionItemTemplate = $(SectionItemTemplate({Styles}))

            this.addChildView(new ContentLabel({
                model: section
            }), sectionItemTemplate.find('.js-content-label'))

            if (!ACL.isAllowed(ACL.resources.PROGRESS, ACL.actions.VIEW) && !ISMOBILE) {
                const progressBarContainer = sectionItemTemplate.find('.js-progress-bar')
                const progressBar = this.addSvelteChildView(progressBarContainer, ProgressBar, {
                    fragments: {
                        blue: section.get('progress')
                    },
                    showLabel: true,
                    width: 50
                })

                progressBarContainer.toggle(Number.isFinite(section.get('progress')))
                this.listenToOnce(section, 'change:progress', (model) => {
                    progressBar.fragments.blue = section.get('progress')
                    progressBar.updateBar()
                    progressBarContainer.toggle(Number.isFinite(model.get('progress')))
                })
            }
            this.$('.js-sections').append(sectionItemTemplate)
        })

    }

}
