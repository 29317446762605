import Styles from './Show.scss';

import Template from './Show.hbs';
import ChapterView from 'views/pages/groups/show/chapterView/ChapterView';
import HeroButton from 'views/components/heroButton/HeroButton';
import ContentLabel from 'views/components/contentLabel/ContentLabel';
import TeacherMaterialModal from 'views/pages/groups/show/teacherMaterial/TeacherMaterial';
import SearchInput from './SearchInput.svelte';

export default BaseView.extend({

    initialize(options) {
        _.bindAll(this,
            'getProgress'
        );

        this.groupId = options.params[0];
        this.groupModel = Backbone.Collection.groups.get(this.groupId);
        this.setElement(Template({
            Styles,
            hasStudentsOrIsNotTeacher: this.groupModel.students.length > 0 || !Backbone.Model.user.get('is_teacher'),
            isStudentOrMobile: Backbone.Model.user.get('is_student') || ISMOBILE,
            hasContent: this.groupModel.chapters.length > 0,
            manageStudentsUrl: `<a href='/groups/settings/${this.groupId}'>`,
            addContentUrl: `<a href='/library/home/${this.groupId}'>`
        }));

        Backbone.View.header.clearButtons()
        Backbone.View.header.setTitle(window.i18n.gettext('Learning material'))
        Backbone.View.header.setCrumblepath(
            this.groupModel.getAllCrumblepathModels(),
            'show'
        )

        this.groupModel.chapters.forEach(chapter => this.renderChapter(chapter));

        // If the user is not allowed to view progress, show it here since it cannot see it
        // within the progress view.
        if (!ACL.isAllowed(ACL.resources.PROGRESS, ACL.actions.VIEW)) {
            this.getProgress();
        }

        this.addHeroButtonForTheoryCollection()
        this.addHeroButtonForTeacherMaterial()
        this.addHeroButtonForSearch()

        if (Backbone.Model.user.get('is_demo')) {
            setTimeout(_.bind(this.openToolTipForGroupsShow, this), 100);
        }
    },

    /**
         * openToolTipForGroupsShow
         *
         * This functions opens a tooltip that points towards the second section of chapter 1.
         *
         */
    openToolTipForGroupsShow() {
        var firstSectionOfFirstChapter = this.$('.js-chapter:nth-child(1) .js-section-item:nth-child(1)');
        var secondSectionOfFirstChapter = this.$('.js-chapter:nth-child(1) .js-section-item:nth-child(2)');
        if (secondSectionOfFirstChapter.length) {
            Backbone.View.layout.addTourTooltip({
                message: window.i18n.gettext('Choose a section'),
                target: secondSectionOfFirstChapter,
                left: true
            });
        } else if (firstSectionOfFirstChapter.length) {
            Backbone.View.layout.addTourTooltip({
                message: window.i18n.gettext('Choose a section'),
                target: firstSectionOfFirstChapter,
                left: true
            });
        }
    },

    renderChapter(chapter) {
        var chapter_view = new ChapterView({
            model: chapter
        });
        this.registerChildView(chapter_view);
        chapter_view.appendTo(this.$('.js-chapters'));
    },

    getProgress() {
        $.get({
            url: '/groups/progress_student/' + this.groupModel.id + '.json',
            success: (response) => {

                // Set the progress for each section
                for (const chapter of response.chapters || []) {
                    for (const section of chapter.progress || []) {
                        const sectionModel = Backbone.Collection.sections.get(section.section_id)
                        sectionModel?.set({
                            progress: section.progress
                        })
                    }
                }

                // Use the activity id with latest response to show the most recent section
                const sectionModel = Backbone.Collection.activities.get(response.lastActivityId)?.getSectionModel()
                if (sectionModel) {
                    this.addChildView(
                        new ContentLabel({
                            model: sectionModel
                        }),
                        '.js-recent-progress-section'
                    )
                    this.$('.js-recent-progress').css('visibility', 'visible')
                } else {
                    this.$('.js-recent-progress').hide()
                }
            },
            error: (response) => {
                // No need to display an error when the request fails
                response.failSilently = true
            }
        })
    },

    addHeroButtonForTheoryCollection() {
        if (this.groupModel.theoryBooks.length > 0) {
            // Take the first theory model and pass it to the constructor, this will work until
            // we give the user the option to select more theories.
            const theoryId = this.groupModel.theoryBooks.at(0).id

            // Render a hero button
            Backbone.View.header.addButton(new HeroButton({
                firstLine: window.i18n.gettext('Open theory'),
                icon: 'theory',
                callback: () => {
                    Backbone.View.layout.openTheory(theoryId)
                },
            }))
        }
    },

    addHeroButtonForTeacherMaterial() {
        // If user is teacher which has teacher materials for the current group, display button for fullscreen
        // model to view said teacher materials.
        if (!Backbone.Model.user.get('is_teacher') || ISMOBILE) {
            return
        }

        const teacherMaterial = this.groupModel.getTeacherMaterial()
        if (teacherMaterial.length) {
            Backbone.View.header.addButton(new HeroButton({
                firstLine: window.i18n.gettext('Teacher materials'),
                icon: 'source-3',
                callback: () => {
                    Backbone.View.Components.fullscreen.open(TeacherMaterialModal, {
                        title: new ContentLabel({
                            model: this.groupModel,
                            hasNoLink: true,
                            labelPrefix: window.i18n.gettext('Teacher materials')
                        }).el.outerHTML,
                        collection: teacherMaterial
                    })
                },
            }))
        }
    },

    addHeroButtonForSearch() {
        const searchButton = Backbone.View.header.addButton(
            new HeroButton({
                firstLine: window.i18n.gettext('Search'),
                icon: 'search',
                callback: () => {
                    searchInput.isVisible = true
                }
            })
        )

        const searchInput = this.addSvelteChildView('.js-search-input', SearchInput, {
            groupId: this.groupId,
            searchButtonElement: searchButton.el
        })
    }

});
