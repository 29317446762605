import Template from './Source11.hbs';
import Util from 'util/util';

// Dynamic score source view
var Source11View = BaseView.extend({

    initialize(options) {

        this.work_on = options.work_on;
        this.listenTo(Backbone.Model.user, 'response_updated', this.render);
        this.render();
    },

    render() {

        var scores_and_labels = this.getContent();

        this.$el.html(Template({
            title: Util.renderContentSafely(this.model.get('title')),
            scores_and_labels
        }));
    },

    getContent() {
        var type_score = {};
        var scores_and_labels = [];

        if (Backbone.Model.user.get('is_student')) {
            _.each(this.model.get('source').parse_responses, function(task_object) {
                var student_response = this.work_on.model.responses.findWhere({
                    task_id: task_object.task_id
                });
                if (student_response && student_response.get('json_answer')) {
                    _.each(task_object.answers, function(answer) {
                        if (student_response.get('json_answer')[0] === answer.answer) {
                            if (type_score[answer.type_id] !== undefined) {
                                type_score[answer.type_id] += answer.score_increment;
                            } else {
                                type_score[answer.type_id] = answer.score_increment;
                            }
                        }
                    }, this);
                }
            }, this);

            _.each(this.model.get('source').parse_results, function(label) {

                scores_and_labels.push({
                    label: label.label,
                    score: type_score[label.index]
                });

            }, this);
        }

        return scores_and_labels;
    }
});

export default Source11View;
