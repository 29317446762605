import Styles from './ReEnterPasswordForm.scss';

import Template from './ReEnterPasswordForm.hbs';
import UsernameAndPassword from 'views/components/register/parts/card/parts/login/UsernameAndPassword'
import Util from 'util/util'
import SSOBar from 'views/components/ssobar/SSOBar'

export default BaseView.extend({

    initialize() {

        this.bindAll([
            'sendCredentials',
        ])

        // Get the email address or username from local storage to find what this user last used
        let previousUsername
        if (Util.hasSupportForLocalstorage()) {
            previousUsername = localStorage.getItem('previousUsername')
        }

        // If there is no previous username, or it is not equal to the email address or username of the current user
        // take the username of the current user as fallback
        if (!previousUsername || (previousUsername !== Backbone.Model.user.get('email') && previousUsername !== Backbone.Model.user.get('username'))) {
            previousUsername = Backbone.Model.user.get('username')
        }

        this.setElement(Template({
            Styles,
            message: window.i18n.gettext('Your session has expired. Login again to continue.')
        }))

        this.userNameAndPasswordView = this.addChildView(new UsernameAndPassword({
            callback: this.sendCredentials,
            previousUsername,
            hidePasswordHelp: true,
        }), '.js-credentials-form')

        // Add buttons for SSO login options
        this.addChildView(
            new SSOBar({}),
            '.js-sso-bar'
        );

        // Add custom logic for when login was successful,
        // for example we need to close the modal here.
        this.listenTo(
            Backbone,
            'login:successful',
            () => {

                // After loading user info, reload the current URL because
                // user and content may have changed
                Backbone.Model.user.loadUserInfo(() => {
                    Backbone.history.loadUrl()
                })

                Backbone.View.layout.openStatus(
                    window.i18n.gettext('You are logged in successfully.'),
                    'success'
                )

                Backbone.View.layout.closeReEnterPasswordModal()
            }
        );
    },

    /**
     * sendCredentials
     *
     * Attempt to login using the previousUsername and an user submitted password.
     *
     * @param {string} username     previousUsername
     * @param {string} password     the password
     */
    sendCredentials(username, password) {

        Backbone.Model.user.logIn(
            username,
            password,
            // Set joinGroupId to undefined since its not needed here
            undefined,
            this.userNameAndPasswordView.submitButtonView
        );
    }

})
