import ShapeList from 'util/ShapeLoader'

export default class FileHelper {

    /**
     * Creates an instance of FileGroup.
     * @param {array} groups array containing allowed groups
     */
    constructor(groups) {
        const groupInfo = FileHelper.groupInfo;

        // Make sure each group is present in groupInfo ('archive' may be present in the task)
        groups = groups.filter((group) => groupInfo[group])

        // If no file groups are selected, we should allow all of them (empty filter)
        if (groups.length === 0) {
            groups = Object.keys(FileHelper.groupInfo)
        }

        // Create a memoized function of the createGroupsProperty to prevent it
        // from executing reduce over and over again.
        const createGroupsProperty = _.memoize((groups) => {
            return groups.reduce(
                (allowedDetails, group) => {
                    return {
                        labels: [
                            ...allowedDetails.labels,
                            groupInfo[group].label
                        ],
                        extensions: [
                            ...allowedDetails.extensions,
                            ...groupInfo[group].extensions
                        ],
                        essentialExtensions: [
                            ...allowedDetails.essentialExtensions,
                            ...groupInfo[group].essentialExtensions
                        ],
                        mimeTypes: [
                            ...allowedDetails.mimeTypes,
                            ...groupInfo[group].mimeTypes
                        ]
                    }
                }, {
                    labels: [],
                    extensions: [],
                    essentialExtensions: [],
                    mimeTypes: []
                }
            );
        });

        this.groups = createGroupsProperty(groups);
    }

    /**
     * Returns an object containg file group information
     *
     * @readonly
     * @static
     */
    static get groupInfo() {
        return {
            get textdocument() {

                const essentialExtensions = [
                    'doc', 'docx', 'txt', 'odt', 'wps', 'pages', 'rtf',
                ];

                const extensions = [
                    ...essentialExtensions,

                    'docm', 'abw', 'epub', 'wpd', 'pap'
                ];

                return {
                    label: window.i18n.gettext('Text document'),
                    icon: 'file',
                    extensions,
                    essentialExtensions,
                    mimeTypes: [
                        'application/vnd.google-apps.document',
                        'application/x-abiword',
                        'application/vnd.oasis.opendocument.text',
                        'text/plain',
                        'application/vnd.ms-works-ss',
                        'application/vnd.ms-works-wp',
                        'application/x-wordperfect',
                        'application/wordperfect',
                        'application/msword',
                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                        'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
                        'application/vnd.ms-word.document.macroEnabled.12',
                        'application/vnd.ms-word.template.macroEnabled.12',
                        'text/rtf',
                    ]
                }
            },

            get image() {

                const essentialExtensions = [
                    'png', 'jpg', 'jpeg', 'gif', 'webp', 'avif',
                ];

                const extensions = [
                    ...essentialExtensions,

                    'bmp', 'tiff', 'psd', 'svg', 'wmf', 'ai', 'jfif', 'pjpeg', 'pjp',

                    // Add 3D files. Maybe in the future they should have their own category
                    'dwg', 'dxf', 'dgn', 'stl', 'step', 'cad', 'skp', 'par', 'crv', 'crv3d'
                ];

                return {
                    label: window.i18n.gettext('Image'),
                    icon: 'image',
                    extensions,
                    essentialExtensions,
                    mimeTypes: [
                        'application/vnd.google-apps.drawing',
                        'application/vnd.google-apps.photo',
                        'application/CDFV2',
                        'image/jpeg',
                        'image/gif',
                        'image/png',
                        'image/x-ms-bmp',
                        'image/svg+xml',
                        'image/svg',
                        'image/tiff',
                        'image/vnd.adobe.photoshop',
                        'application/dxf',
                        'application/acad',
                        'image/vnd.dwg',
                        'image/x-dwg',
                        'image/vnd.dxf',
                        'image/vnd.dgn',
                        'image/webp',
                        'image/avif',
                    ],
                }
            },

            get pdf() {

                const essentialExtensions = [
                    'pdf'
                ];

                const extensions = [
                    ...essentialExtensions
                ];

                return {
                    label: window.i18n.gettext('PDF'),
                    icon: 'theory',
                    extensions,
                    essentialExtensions,
                    mimeTypes: [
                        'application/pdf',
                    ],
                }
            },

            get presentation() {

                const essentialExtensions = [
                    'ppt', 'pptx', 'keynote', 'odp'
                ];

                const extensions = [
                    ...essentialExtensions,

                    'gslides', 'key', 'pex', 'pps', 'dps'
                ];

                return {
                    label: window.i18n.gettext('Presentation'),
                    icon: 'presentation',
                    extensions,
                    essentialExtensions,
                    mimeTypes: [
                        'application/vnd.google-apps.presentation',
                        'application/vnd.oasis.opendocument.presentation',
                        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                        'application/vnd.openxmlformats-officedocument.presentationml.template',
                        'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
                        'application/vnd.ms-powerpoint.addin.macroEnabled.12',
                        'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
                        'application/vnd.ms-powerpoint.template.macroEnabled.12',
                        'application/vnd.ms-powerpoint.slideshow.macroEnabled.12',
                        'application/vnd.ms-powerpoint',
                    ],
                }
            },

            get spreadsheet() {

                const essentialExtensions = [
                    'xls', 'xlsx', 'csv', 'tsv', 'numbers'
                ];

                const extensions = [
                    ...essentialExtensions,

                    'xlsm', 'gsheet', 'ods', 'sxc', 'wks', 'et',

                    // CMA Coach (measuring and graphing for science courses)
                    'cma', 'cma7', 'cmr', 'cmr7'
                ]

                return {
                    label: window.i18n.gettext('Spreadsheet'),
                    icon: 'trend-up',
                    extensions,
                    essentialExtensions,
                    mimeTypes: [
                        'application/vnd.google-apps.spreadsheet',
                        'application/vnd.oasis.opendocument.spreadsheet',
                        'application/vnd.ms-excel',
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
                        'application/vnd.ms-excel.sheet.macroEnabled.12',
                        'application/vnd.ms-excel.template.macroEnabled.12',
                        'application/vnd.ms-excel.addin.macroEnabled.12',
                        'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
                        'text/csv',
                        'text/tsv',
                    ],
                }
            },

            get video() {

                const essentialExtensions = [
                    'avi', 'mkv', 'mpeg', 'mpg', 'mp4', 'mov', 'webm'
                ];

                const extensions = [...essentialExtensions];

                return {
                    label: window.i18n.gettext('Video'),
                    icon: 'video',
                    extensions,
                    essentialExtensions,
                    mimeTypes: [
                        'application/vnd.google-apps.video',
                        'video/x-msvideo',
                        'video/x-flv',
                        'video/x-matroska',
                        'video/mpeg',
                        'video/webm',
                        'video/mp4',
                        'video/quicktime',
                        'video/x-m4v',
                    ],
                }
            },

            get audio() {

                const essentialExtensions = [
                    'mp3', 'wav', 'm4a'
                ];

                const extensions = [...essentialExtensions];

                return {
                    label: window.i18n.gettext('Audio'),
                    icon: 'video',
                    extensions,
                    essentialExtensions,
                    mimeTypes: [
                        'application/vnd.google-apps.audio',
                        'audio/mpeg',
                        'audio/wav',
                        'audio/x-wav',
                        'audio/x-m4a',
                        'audio/ogg',
                        'audio/m4a',
                        'audio/webm',
                    ],
                }
            },

            get programming() {

                const essentialExtensions = [
                    // HTML, CSS
                    'html', 'css',
                    // PHP
                    'php',
                    // JavaScript, TypeScript, JavaScript module
                    'js', 'ts', 'mjs',
                    // Python
                    'py',
                    // Java, Java Class, Kotlin
                    'java', 'class', 'kt',
                    // C#
                    'cs',
                    // C, C header file, Arduino sketch (C), C++
                    'c', 'h', 'ino', 'cpp',
                    // Data
                    'json', 'hex', 'xml', 'yml', 'toml',
                ];

                const extensions = [...essentialExtensions];

                return {
                    label: window.i18n.gettext('Programming'),
                    icon: 'code',
                    extensions,
                    essentialExtensions,
                    mimeTypes: [
                        'text/plain',
                        'text/html',
                        'application/x-httpd-php',
                        'text/javascript',
                        'text/css',
                        'text/x-script.python',
                        'text/xml',
                        'application/x-java-applet',
                        'text/x-java',
                        'text/x-c',
                        'application/json',
                    ]
                }

            }

        }
    }

    /**
     * Returns an array containing the labels for given groups
     * in the constructor.
     *
     * @readonly
     * @static
     */
    get groupLabels() {
        return this.groups.labels;
    }

    /**
     * Returns an array containing the (file-)extensions for given groups
     * in the constructor.
     *
     * @readonly
     * @static
     */
    get fileExtensions() {
        return this.groups.extensions;
    }

    /**
     * Returns a reduced list of only the essentials (most populair) extensions.
     * This can for example be used within the Office365 integration which crashes
     * if there are to many extensions sent to microsoft.
     *
     * @readonly
     * @memberof FileHelper
     */
    get fileExtensionsEssentials() {
        return this.groups.essentialExtensions;
    }

    /**
     * Returns an array containing the mime-types for given groups
     * in the constructor.
     *
     * @readonly
     * @static
     */
    get fileMimeTypes() {
        return this.groups.mimeTypes;
    }

    /**
     * Returns the icon by matching the selector argument
     * to the given selectfield field. For example:
     * `png` is the selector for 'extensions` group. It
     * should return `image`
     *
     * @static
     * @param {string} selector string to search for within selectfield
     * @param {string} selectField string to select the field to search in
     * @param {boolean} svg boolean whether to return an SVG or just the name
     * @returns {string} SVG icon of the matched group
     */
    static getIcon(selector, selectField, svg) {

        let match = _.find(FileHelper.groupInfo, ({ [selectField]: options }) => _.contains(options, selector));
        match = (match && match.icon);

        if (svg !== undefined && !svg) {
            return match;
        }

        return (match && ShapeList[match]) || ShapeList['file'];
    }
}
